// Await/async imports
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Sentry
import * as Sentry from '@sentry/browser';

// Only bind when production mode is set
if (window.SENTRY_DSN) {
  console.log('Setting up default Sentry client');

  // Create the default sentry client
  Sentry.init({
    dsn: window.SENTRY_DSN,
    tunnel: '/_stun',
    release: window.SENTRY_RELEASE,
    environment: window.DEPLOYMENT_ENVIRONMENT,
  });

  Sentry.setUser({
    id: window.SENTRY_USERID,
    username: window.SENTRY_USERNAME,
    email: window.SENTRY_EMAIL,
    ip_address: window.SENTRY_IP_ADDRESS,
  });
}

declare global {
  interface Window {
    DEPLOYMENT_ENVIRONMENT: string;
    HAS_MULTIPLE_HOSTS: boolean;
    SENTRY_DSN: string;
    SENTRY_RELEASE: string;
    SENTRY_USERID?: string;
    SENTRY_USERNAME: string;
    SENTRY_EMAIL?: string;
    SENTRY_IP_ADDRESS: string;
  }

  interface Array<T> {
    move: (from: number, to: number) => T[];
  }
}
